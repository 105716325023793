body {
  margin: 0;
  background: #000;
  font-family: 'Roboto', sans-serif;
}

.brandlg {
  background: url(https://d24cje2pn3geuc.cloudfront.net/images/logo-trakmd.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 140px;
  height: 29px;
  z-index: 9;
  opacity: 0.3;
  flex: 1;
}
.roomContainer {
  float: left;
  width: 100%;
  height: 100%;
}

.hidden {
  display: none !important;
}

.fullScreen {
  float: left;
  width: 100%;
  height: 100%;
}

.smallVideo {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 999;
  width: 200px;
  height: 120px;
  background: #dddddd;
  border-radius: 10px;
}

.controlContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.speakerOn .off,
.videoOn .off {
  display: none !important;
}

.speakerOn .on,
.videoOn .on {
}

.speakerOff .off,
.videoOff .off {
  display: flex !important;
}

.speakerOff .on,
.videoOff .on {
  display: none !important;
}

.call_act_button {
  width: 50px;
  height: 50px;
  border-radius: 0;
  background: #2929294f;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin: 0 0;
  display: flex !important;
  position: relative;
  cursor: pointer;
}

.call_act_button.active {
  background: #0079ff;
}

.call_act_button.full {
  width: auto;
  height: 40px;
  margin-right: 20px;
  padding: 0 12px;
  border-radius: 30px;
}

.call_act_button:hover {
  background: #171717;
}
.call_act_button.end {
  background: #f4433687;
}
.call_act_button.end:hover {
  background: #f44336;
}
.call_act_button .count {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #a30;
  top: 5px;
  right: 7px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
}
.call .off {
  display: none !important;
}

.call .on {
  display: flex !important;
  background: red;
}

.callEnd .off {
  display: flex !important;
  background: green;
}

.callEnd .on {
  display: none !important;
}

.videoContainer {
  height: 100%;
  position: relative;
  width: 100%;
}

.chatContainer {
  display: none;
  height: 100%;
  width: 100%;
  max-width: 500px;
  background: #eef1f6;
}

.chatContainer.isopen {
  display: block;
}

.chatContainer .heading {
  display: flex;
  padding: 10px;
  justify-content: center;
  margin-bottom: 15px;
}

.chatContainer .heading .head_text {
  font-size: 14px;
  color: #5f5f5f;
  font-weight: bold;
}
.chatContainer .chatbox {
  height: 100%;
}

.chatContainer .chatbox .chatBox {
  height: calc(100% - 265px);
}
.chatContainer .chatParticipants {
  display: flex;
  flex-direction: column;
}

.chatContainer .chatParticipants .participant {
  display: flex;
  justify-content: left;
  padding: 3px 10px;
}
.chatContainer .chatParticipants .participant .status {
  margin-right: 10px;
}
.chatContainer .chatParticipants .participant .status .online {
  width: 0.6em;
  height: 0.6em;
  color: #8bc34a;
}

.chatContainer .chatParticipants .participant .status .offline {
  width: 0.6em;
  height: 0.6em;
  color: #ccc;
}

.chatContainer .chatParticipants .participant .det {
  font-size: 14px;
}

.chatContainer .chatBox .chatinput {
  position: absolute;
  bottom: 0;
  box-shadow: 0 0 3px #0000002e;
  width: 100%;
  display: flex;
}

.chatContainer .chatBox .chatinput .input {
  display: flex;
  flex: 1;
}

.chatContainer .chatBox .chatinput .input .messagetxt {
  display: flex;
  padding: 10px;
  flex: 1;
  border: none;
  outline: 0;
  resize: none;
}
.chatContainer .chatBox .chatinput .sendbutton {
  display: flex;
  height: 40px;
  width: 40px;
  margin: 10px;
  justify-content: center;
}

.chatContainer .chatBox .chatinput .sendbutton .send {
  background: #03a9f4;
  border: none;
  color: #ffffff;
  font-weight: bold;
  height: 40px;
  padding: 8px 9px;
  width: 40px;
  border-radius: 100%;
  font-size: 14px;
  cursor: pointer;
}

.chatContainer .chatBox .messagescontainer {
  height: 100%;
  display: flex;
  position: relative;
  flex: 1;
  overflow: auto;
  justify-content: left;
  padding: 0;
  flex-direction: column;
}
.chatContainer .chatBox .messagescontainer .msgbox > div {
  padding: 0 10px;
  box-sizing: border-box;
}
.chatContainer .chatBox .messagescontainer .message {
  padding: 0;
  border-radius: 5px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.chatContainer .chatBox .messagescontainer .message.mine {
  text-align: right;
  box-sizing: border-box;
}

.chatContainer .chatBox .messagescontainer .message.start {
  margin-top: 10px;
}

.chatContainer .chatBox .messagescontainer .message .authordet {
  font-size: 12px;
  color: #555;
  font-weight: normal;
  margin-bottom: 4px;
  margin-left: 3px;
}

.chatContainer .chatBox .messagescontainer .message .messagecontent {
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 13px;
  background: #f4f4f8;
  clear: both;
  max-width: 80%;
  width: auto;
  float: left;
  margin-bottom: 5px;
  white-space: pre-line;
}
.chatContainer .chatBox .messagescontainer .message.mine .messagecontent {
  float: right;
}

.chatContainer .chatBox .messagescontainer .message.mine .messagecontent {
  background: #007aff;
  color: #fff;
}

.chatContainer .chatBox .messagescontainer .message.imagemessage .messagecontent {
  padding: 0;
  border: solid 3px #f4f4f8;
}

.chatContainer .chatBox .messagescontainer .message.mine.imagemessage .messagecontent {
  border: solid 3px #007aff;
}

.enter {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  background: #fff;
}

.enter:before {
  background-color: #edeef1;
  height: 2px;
  width: 100%;
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
}

.enter__submit {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 38px;
  height: 38px;
  border: 2px solid #e2e3e7;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
}

.enter__textarea {
}

.enter__textarea textarea {
  width: 100%;
  border: none;
  resize: none;
  outline: 0;
  font-size: 14px;
}

.enter__icons {
  padding-right: 50px;
  font-size: 0;
  align-items: center;
  margin-top: 6px;
  justify-content: space-between;
  width: 100%;
}

.enter__icons .fa-paperclip {
  font-size: 16px;
  color: grey;
}

.enter__icons .fa-smile-o {
  font-size: 16px;
  color: grey;
}

.enter__icon {
  color: #505050;
  border-radius: 5px;
  width: 60px;
  height: 30px;
  background: #eee;
  margin: 6px 10px;
  float: left;
  text-align: center !important;
}

.enter__icon.right {
  float: right;
}

.enter__icon svg {
  width: 0.7em;
  position: relative;
  top: 3px;
}

.chatfileinput {
  display: none;
}

.enter__textarea .selectedfile {
  width: 82%;
  background: #ffc107;
  border-radius: 30px;
  color: #fff;
  height: 33px;
  padding: 3px 8px;
  margin: 5px auto;
}
.enter__textarea .selectedfile .name {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ffffff;
  float: left;
  line-height: 2;
}

.enter__textarea .selectedfile .action {
  float: right;
  margin-top: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.chatContainer .messagescontainer .message .image {
  float: left;
  width: 100%;
  max-width: 150px;
  height: 100%;
  max-height: 150px;
  overflow: hidden;
}

.chatContainer .messagescontainer .message .image img {
  width: 100%;
  object-fit: contain !important;
}

.chatContainer .messagescontainer .message.imagemessage .doc {
  float: left;
  width: 100%;
}

.chatContainer .messagescontainer .message.imagemessage .doc .saveicon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #d7d7e6;
  text-align: center;
  margin: 5px;
  position: relative;
  float: left;
}

.chatContainer .messagescontainer .message.imagemessage .doc .saveicon svg {
  position: relative;
  padding: 12px;
}

.chatContainer .messagescontainer .message.mine.imagemessage .doc .saveicon {
  background: #0764cc;
}

.chatContainer .messagescontainer .message.imagemessage .doc .details {
  float: left;
  width: 59%;
  text-align: left;
  position: relative;
  top: 9px;
}

.chatContainer .messagescontainer .message.imagemessage .doc .details .docname {
  float: left;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chatContainer .messagescontainer .message.imagemessage .doc .details .action {
  float: left;
}

.chatContainer .messagescontainer .message.imagemessage .doc .details .action ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chatContainer .messagescontainer .message.imagemessage .doc .details .action ul li {
  display: inline-block;
  margin: 5px 0;
}
.chatContainer .messagescontainer .message.imagemessage .doc .details .action ul li a {
  color: #555;
  margin: 5px 0;
}
.chatContainer .messagescontainer .message.mine.imagemessage .doc .details .action ul li a {
  color: #fff;
}

.attachmentsContainer {
  height: 100%;
}

.attachmentsContainer .header {
  float: left;
  width: 100%;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  background: #f1f1f1;
}

.closechatbutton {
  display: none;
  background: #eee;
  color: #555;
  font-size: 15px;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 30px;
  position: absolute;
  left: 10px;
  top: 5px;
  line-height: 1.7;
}

.closechatbutton svg {
  float: left;
}

.initialName {
  position: absolute;
  height: 50px;
  width: 50px;
  margin: 20px 24px;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  background: #fff;
  box-shadow: 0 0 11px #00000070;
  border-radius: 100%;
  padding: 5px;
  line-height: 2;
  color: #555;
}
#SRLLightbox {
  z-index: 9999;
}
.consult_end_title {
  font-size: 16px;
  color: #656565;
  font-weight: normal;
  margin: 30px 0;
}

.onlyAudio {
  display: none;
}

.SRLControls {
  left: 0;
}
.SRLControls .SRLCloseButton {
  background: #a30;
  color: #fff;
}

.speaker_identity {
  flex: 1 1;
  font-weight: 700;
  color: #fff;
  font-size: 13px;
}

.speaker_identity span {
  float: left;
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
}

.timer {
  min-width: 50px;
  border-radius: 30px;
  font-weight: bold;
  color: #dedede;
  text-align: center;
}
.timer span {
  font-size: 25px;
}
.settingsButton {
  padding: 6px !important;
  margin-right: 13px !important;
  color: #fff !important;
}
@media screen and (max-width: 1150px) {
  .chatContainer {
    position: absolute;
    right: 0;
    z-index: 9999;
  }
  .closechatbutton {
    display: block;
  }

  .callstartbutton .txt {
    display: none;
  }
}

@media screen and (max-width: 670px) {
  .showControls {
    bottom: 120px !important;
  }
}

.waiting_modal {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  color: #fff;
  text-align: center;
}

.waiting_modal .modal_content {
  width: 100%;
  margin: auto;
  background: #000000a3;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 17px #000;
}
